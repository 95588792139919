import Header from '../../components/Header';
import WhatWeDo from '../../components/WhatWeDo';
import VideoMasthead from '../../components/VideoMasthead';
import AboutUs from '../../components/AboutUs';
import Team from '../../components/Team';
import ClientShowcase from '../../components/ClientShowcase';
import Footer from '../../components/Footer';

import React, { useState, useEffect } from 'react';

import '../../App.css';


function HomePage() {
  return (
    <div className="App">
      <Header />
      <section id="VideoMasthead">
        <VideoMasthead />
      </section>
      <section id="WhatWeDo">
        <WhatWeDo />
      </section>
      <section id="AboutUs">
        <AboutUs />
      </section>
      <section id="Team">
        <Team />
      </section>
      <section id="Clients">
        <ClientShowcase />
      </section>
      <section id="Contact">
        <Footer />

      </section>
    </div>
  );
}

export default HomePage;
