import React, { useState, useRef, useEffect } from 'react';
import './Header.css';
import logo from '../../assets/Martin-red.png';
import HamburgerIcon from '../../assets/Hamburger_icon.svg.png';

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const navRef = useRef(null);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      const offset = -document.querySelector('.header').offsetHeight;
      const elementPosition = element.getBoundingClientRect().top + window.pageYOffset + offset;
      window.scrollTo({ top: elementPosition, behavior: 'smooth' });
    }
    setMenuOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (navRef.current && !navRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    };

    if (menuOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [menuOpen]);

  return (
    <header className={`header ${menuOpen ? 'open' : ''}`}>
      <img src={logo} alt="Logo" className="redLogo" />
      <div className="established">- since 1977 -</div>
      <div className={`navSection ${menuOpen ? 'mobile show' : 'mobile'}`} ref={navRef}>
        <div className="navElements mobile" onClick={() => scrollToSection('AboutUs')}>ABOUT</div>
        <div className="navElements mobile" onClick={() => scrollToSection('Team')}>TEAM</div>
        <div className="navElements mobile" onClick={() => scrollToSection('Clients')}>CLIENTS</div>
        <div className="navElements mobile sayHelloButton" onClick={() => scrollToSection('Contact')}>SAY HELLO</div>
      </div>
      <img src={HamburgerIcon} alt="Menu" className="hamburger-menu" onClick={toggleMenu} />
      <nav className="navSection">
        <div className="navElements" onClick={() => scrollToSection('AboutUs')}>ABOUT</div>
        <div className="navElements" onClick={() => scrollToSection('Team')}>TEAM</div>
        <div className="navElements" onClick={() => scrollToSection('Clients')}>CLIENTS</div>
        <div className="navElements sayHelloButton" onClick={() => scrollToSection('Contact')}>SAY HELLO</div>
      </nav>
    </header>
  );
};

export default Header;
